import {
    getItemLaunchType,
    getItemTypeFromMetadata,
} from '../../store/draft/types.js';
import { toDurationFromMetadata } from '../../helpers/converters.js';
import { translate } from '../../helpers/translate.js';

export const getItemChanges = (item, elearning) => {
    return {
        launchId: elearning.targetId,
        elearningItemName: elearning.title,
        launchType: item.launchType || getItemLaunchType(elearning),
        playerType: elearning.playerType,
        name: hasNewItemName(item.name) ? elearning.title : item.name,
        type: item.type || getItemTypeFromMetadata(elearning.metadata),
        duration: item.duration || toDurationFromMetadata(elearning.metadata),
    };
};

const hasNewItemName = (name) => [translate('newBlockItem'), translate('newBlock')].indexOf(name) > -1;