/* eslint-disable indent */
import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { map } from  'lit/directives/map.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    updateDraftItem,
    copyDraftItem,
    changeCompoundType,
    deleteDraftItem,
    moveDraftItem,
    createDraftSubItem,
    sortDraftSubItems,
} from '../../store/draft/draft.js';
import {
    previewElearning,
    previewMicrolearning,
} from '../../store/preview/preview.js';
import {
    compoundTypes,
    compositionTypes,
    itemTypes,
    toCompoundType,
    isCompoundItem,
    getIcon,
    itemTypeRenderer,
    completionTypes,
    compoundCompletionTypes,
} from '../../store/draft/types.js';
import { translate } from '../../helpers/translate.js';
import {
    toDuration,
    fromDuration,
} from '../../helpers/converters.js';
import { getItemChanges } from './helpers.js'
import { scrollIntoViewIfNeeded } from '../../helpers/dom.js';
import { selectRenderer } from '@vaadin/select/lit.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '@vaadin/select/theme/material/vaadin-select.js';
import '../shared/sdb-sortable-list.js';
import '../shared/sdb-dialog.js';
import '../shared/sdb-image-editor.js';
import '../elearning/sdb-microlearning-select-input.js';
import '../elearning/sdb-elearning-select-input.js';
import './sdb-composition-draft-sub-item.js';

const mapStateToProps = (state) => ({
    draftId: state.draft.current.id,
    draft: state.draft.current,
    legacy: state.draft.current.compositionType === compositionTypes.COMPOSITIONTYPE_LEGACY,
    settings: state.env.settings,
    hasGlobalId: state.env.hasGlobalId,
});

const mapDispatchToProps = (dispatch) => ({
    updateDraftItem: (itemId, changes) => dispatch(updateDraftItem({ itemId, changes })),
    copyDraftItem: (item) => dispatch(copyDraftItem({ item })),
    changeCompoundType: (itemId, newType) => dispatch(changeCompoundType({ itemId, newType })),
    deleteDraftItem: (itemId) => dispatch(deleteDraftItem({ itemId })),
    moveDraftItem: (itemId, direction) => dispatch(moveDraftItem({ itemId, direction })),
    createDraftSubItem: (itemId) => dispatch(createDraftSubItem({ itemId })),
    sortDraftSubItems: (itemId, indexes) => dispatch(sortDraftSubItems({ itemId, indexes })),
    previewElearning: (item) => dispatch(previewElearning({ item })),
    previewMicrolearning: (microlearning) => dispatch(previewMicrolearning({ microlearning })),
});

@customElement('sdb-composition-draft-item')
class SdbCompositionDraftItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Object })
    accessor item;

    @property({ type: Boolean})
    accessor isFirst;

    @property({ type: Boolean })
    accessor isLast;

    @state()
    accessor draftId;

    @state()
    accessor draft;

    @state()
    accessor user;

    @state()
    accessor inEditMode = false;

    @state()
    accessor inSortMode = false;

    constructor() {
        super();
        this._subItemIndexes = [];
    }

    get deleteItemDialog() {
        return this.renderRoot.querySelector('#delete_item_dialog');
    }

    get draftItemBottom() {
        return this.renderRoot.querySelector('#draft_item_bottom');
    }

    get imageUrl() {
        if (!this.item.image) {
            return false;
        }

        if (this.item.image.indexOf('http') === 0) {
            return this.item.image;
        }

        return `${this.settings.imageEndpoint}${this.draftId}/${this.item.image}`;
    }

    get isRequiredCompletionType() {
        return this.draft.progressSettings?.completionType === completionTypes.REQUIRED_BLOCKS;
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                .item-content-card {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }

                paper-item:not([disabled]) {
                    cursor: pointer;
                }

                paper-item-body {
                    border: none;
                    width: 100%;
                }

                paper-item-body div.title {
                    font-family: var(--font-family);
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--text-primary);
                    --webkit-font-smoothing: antialiased;
                }

                sdb-content-iconbutton {
                    color: rgba(0, 0, 0, .7);
                }

                sdb-content-iconbutton[disabled] {
                    color: var(--paper-icon-button-disabled-text, var(--disabled-text-color));
                }

                iron-icon.drag {
                    opacity: .8;
                }

                iron-icon.medium {
                    --iron-icon-height: 24px;
                    --iron-icon-width: 24px;
                }

                paper-icon-item {
                    margin-left: 0px !important;
                    background-color: white;
                }

                .item-title-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 10px;
                }

                .item-buttons {
                    margin-left: 61px;
                    margin-top: 10px;
                }

                paper-input.small {
                    width: 300px;
                }

                paper-toggle-button {
                    margin-top: 16px;
                }

                .toggle {
                    line-height: 45px;
                    width: 300px;
                    display: flex;
                    align-items: center;
                }

                .toggle-label {
                    margin-right: 15px;
                }

                .paper-toggle-button {
                    margin-top: 3px;
                }

                .flexRow {
                    display: flex;
                    flex-direction: row;
                }

                .flexColumn {
                    display: flex;
                    flex-direction: column;
                }

                app-sortable-item {
                    margin-top:2px;
                    margin-bottom: 4px;
                }

                app-sortable-item:last-child {
                    margin-bottom: 2px;
                }

                .subitem-title {
                    font-family: var(--font-family);
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--text-primary);
                    --webkit-font-smoothing: antialiased;
                    margin-left: 25px;
                }
            `,
        ];
    }

    render() {
        const update = (changes) => this.updateDraftItem(this.item.uniqueId, changes);

        return html`
            <sdb-content-card>
                <div class="item-content-card">

                    <!-- image -->
                    <sdb-image-editor
                        image=${this.item.image}
                        draftId=${this.draftId}
                        iconType=${this.item.type}
                        @change=${(e) => { update({ image: e.detail.value }); }}>
                    </sdb-image-editor>

                    <!-- title header -->
                    <div class="item-title-header">
                        <div>
                            <paper-icon-item>

                                <div slot="item-icon">
                                    <sdb-content-icon icon="${getIcon(this.item.type)}" class="medium fallback-icon"></sdb-content-icon>
                                </div>

                                <paper-item-body style="cursor: pointer;" @click=${() => { this._toggleEditMode(); }}>
                                    <div class="title">${this.item.name}</div>
                                </paper-item-body>

                                <sdb-content-iconbutton
                                    id="move_up_button"
                                    class="noPadding medium"
                                    icon="fal fa-arrow-up"
                                    ?disabled=${this.isFirst}
                                    @click=${(e) => { this.moveDraftItem(this.item.uniqueId, 'up'); }}>
                                </sdb-content-iconbutton>
                                <sdb-content-iconbutton
                                    id="move_down_button"
                                    class="noPadding medium"
                                    icon="fal fa-arrow-down"
                                    ?disabled=${this.isLast}
                                    @click=${(e) => { this.moveDraftItem(this.item.uniqueId, 'down'); }}>
                                </sdb-content-iconbutton>

                                <paper-menu-button dynamic-align>
                                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                                    <paper-listbox slot="dropdown-content">
                                        <paper-item
                                            ?hidden=${!isCompoundItem(this.item.type)}
                                            @click=${() => { this._toggleSort(); }}>
                                            ${translate('sort')}
                                            <sdb-content-icon
                                                ?hidden=${!this.inSortMode}
                                                icon="fal fa-check"
                                                class="medium"
                                                style="margin-left: 10px;"
                                            ></sdb-content-icon>
                                        </paper-item>
                                        <paper-item
                                            @click=${()=> { this.copyDraftItem(this.item); }}>
                                            ${translate('duplicate')}
                                        </paper-item>
                                        <paper-item
                                            ?hidden=${isCompoundItem(this.item.type)}
                                            ?disabled=${!this.item.launchId}
                                            @click=${() => { this.previewElearning(this.item); }}>
                                             ${translate('preview')}
                                        </paper-item>
                                        <paper-item
                                            class="warning"
                                            @click=${() => { this.deleteItemDialog.open(); }}>
                                            ${translate('delete')}
                                        </paper-item>
                                    </paper-listbox>
                                </paper-menu-button>

                            </paper-icon-item>
                        </div>

                        <!-- item edit -->
                        ${this.inEditMode? html`
                            <div>
                                <div style="display: flex; flex-direction: column; margin: 0 20px;">
                                    ${this._renderEditMode()}
                                </div>
                            </div>` : nothing
                        }

                        <!-- items -->
                        ${this.item.items ? html`
                            <div style="margin-top: 20px; margin-left: 20px; margin-right: 30px;">
                                ${this.inSortMode
                                    ? html`
                                        <sdb-sortable-list @sorted=${(e) => this._subItemIndexes = e.detail.indexes}>
                                            ${repeat(this.item.items, (subItem) => subItem.uniqueId, (subItem) => html`
                                                <sdb-sortable-item>
                                                    <div style="display: flex; flex-direction: row;">
                                                        <paper-icon-item style="width: 100%; height: 52px; background:#f1f1f1">
                                                            <div slot="item-icon">
                                                                <sdb-content-icon
                                                                    icon="fal fa-arrows-up-down-left-right"
                                                                    class="medium drag"
                                                                ></sdb-content-icon>
                                                            </div>
                                                            <sdb-content-icon
                                                                icon=${getIcon(subItem.type)}
                                                                style="margin-left: -14px;"
                                                                class="medium"
                                                            ></sdb-content-icon>
                                                            <paper-item-body>
                                                                <div class="subitem-title">${subItem.name}</div>
                                                            </paper-item-body>
                                                        </paper-icon-item>
                                                    </div>
                                                </sdb-sortable-item>
                                            `)}
                                        </sdb-sortable-list>
                                    `
                                    : html`
                                        ${map(this.item.items, (subItem) => html`
                                            <sdb-composition-draft-sub-item .parentId=${this.item.uniqueId}
                                                                            .item=${subItem}></sdb-composition-draft-sub-item>
                                        `)}
                                    `
                                }
                            </div>` : nothing
                        }

                        <!-- add subitems -->
                        ${isCompoundItem(this.item.type) ? html`
                            <div class="item-buttons">
                                <sdb-content-button
                                    id="new_block_button"
                                    label=${translate('newBlockItem')}
                                    secondary
                                    @click=${() => { this.createDraftSubItem(this.item.uniqueId); }}>
                                </sdb-content-button>
                            </div>` : nothing
                        }
                    </div>
                </div>
            </sdb-content-card>

            <div id="draft_item_bottom" style="margin-bottom: 10px;"></div>

            <sdb-dialog
                type="confirm"
                id="delete_item_dialog"
                heading=${translate('deleteBlockHeading')}
                confirmCaption=${translate('delete')}
                @confirm=${() => { this.deleteDraftItem(this.item.uniqueId); }}>
            </sdb-dialog>
        `;
    }

    _renderEditMode() {
        const compoundItem = isCompoundItem(this.item.type);
        const update = (changes) => this.updateDraftItem(this.item.uniqueId, changes);

        return html`
            <vaadin-select
                id="compound_select"
                transparent
                label=${translate('blockType')}
                value=${toCompoundType(this.item.type)}
                @change=${(e) => { this.changeCompoundType(this.item.uniqueId, e.target.value); }}
                .items=${Object.values(compoundTypes).map((t) => ({
                    label: translate(t),
                    value: t,
                    disabled: t === compoundTypes.SINGLE_ITEM && (this.item.items || []).length > 1,
                }))}>
            </vaadin-select>

            ${!compoundItem ? html`
                <sdb-elearning-select-input
                    id="elearning_select"
                    labelText=${translate('blockItem')}
                    launchId=${this.item.launchId}
                    launchName=${this.item.elearningItemName}
                    @itemSelected=${(e) => {
                        update(getItemChanges(this.item, e.detail.elearning));
                    }}>
                </sdb-elearning-select-input>
                <vaadin-select
                    id="itemtype_select"
                    transparent
                    label=${translate('blockItemType')}
                    value=${this.item.type}
                    @value-changed=${(e) => { update({ type: e.target.value }); }}
                    ${selectRenderer(itemTypeRenderer, itemTypes)}>
                </vaadin-select>` : nothing
            }

            <paper-input
                id="title_input"
                transparent
                label=${translate('displayTitle')}
                value=${this.item.name}
                @change="${(e) => { update({ name: e.target.value }); }}">
            </paper-input>

            ${this.item.type !== compoundTypes.COMPOUND ? html`
                <paper-input
                    id="duration_input"
                    transparent
                    class="small"
                    type="number"
                    label=${translate('duration')}
                    value=${fromDuration(this.item.duration)}
                    @change=${(e) => { update({ duration: toDuration(e.target.value) }); }}>
                    <div slot="suffix">${translate('minutes')}</div>
                </paper-input>` : nothing
            }

            ${this.item.type === compoundTypes.COMPOUND_TEST && this.hasGlobalId ? html`
                <paper-input
                    id="masteryscore_input"
                    transparent
                    class="small"
                    type="number"
                    min=0
                    max=100
                    label=${translate('masteryScore')}
                    value=${this.item.masteryScore}
                    @change=${(e) => { update({ masteryScore: Number(e.target.value) }); }}>
                </paper-input>` : nothing
            }

            ${this.isRequiredCompletionType ? html`
                <paper-toggle-button
                    id="success_toggle"
                    ?checked=${this.item.compositionSuccessDeterminator}
                    @change=${(e) => {
                        update({
                            compositionSuccessDeterminator: e.target.checked,
                            progressType: e.target.checked ? compoundCompletionTypes.ANY_ITEM : null,
                        });
                    }}>
                    <span>${translate('successDeterminator')}</span>
                </paper-toggle-button>` : nothing
            }

            ${this.item.type === compoundTypes.COMPOUND ? html`
                <vaadin-select
                    id="compoundcompletiontype_select"
                    transparent
                    class="medium"
                    label=${translate('progressType')}
                    value=${String(this.item.progressType)}
                    ?hidden=${this.isRequiredCompletionType && !this.item.compositionSuccessDeterminator}
                    @change=${(e) => update({ progressType: +e.target.value })}
                    .items=${Object.entries(compoundCompletionTypes).map(([key, value]) => ({
                        value: String(value),
                        label: translate(key)
                    }))}>
                </vaadin-select>` : nothing
            }
        `;
    }

    _toggleEditMode() {
        this.inEditMode = !this.inEditMode;
        if (this.inEditMode) {
            setTimeout(() => {
                scrollIntoViewIfNeeded(this.draftItemBottom);
            }, 0);
        }
    }

    _toggleSort() {
        this.inSortMode = !this.inSortMode;
        if (!this.inSortMode && this._subItemIndexes.length) {
            this.sortDraftSubItems(this.item.uniqueId, this._subItemIndexes);
        }
    }


}

